<template>
  <div class="dialog" :class="isDarkOverlay ? 'bg-overlay' : 'bg-defaultOverlay'">
    <div :class="containerClass">
      <div class="dialog-container-default relative p-6 mx-6 sm:mx-0">
        <CloseIcon
          v-if="isShowCloseBtn"
          @click="cancel"
          class="close-dialog-icon cursor-pointer animation-focus-default w-6 h-6 absolute top-4 right-4 sm:top-7 sm:right-7"
        />
        <div
          class="flex flex-col"
          :class="{
            'items-center': scheme === SCHEMES.primary,
          }"
        >
          <div
            v-if="icon"
            class="bg-orange-bgr rounded-full flex justify-center items-center w-12 h-12 mb-5 cursor-pointer animation-focus-default"
          >
            <DeleteIcon v-if="icon === 'delete'" class="w-4 h-4"></DeleteIcon>
            <CommentIcon v-if="icon === 'comment'" class="icon w-4 h-4"></CommentIcon>
            <CTAIcon v-if="icon === 'CTA'" class="w-6 h-6"></CTAIcon>
          </div>
          <h5
            v-if="header"
            class="font-semibold mb-2"
            :class="{
              'text-gray-900 text-md lg:text-2xl font-normal': scheme === SCHEMES.secondary,
              'truncate w-[90%] lg:w-[46%]': isTruncate,
            }"
          >
            {{ header }}
          </h5>
          <div
            v-if="text"
            class="mb-6"
            :class="{
              'text-gray-700 text-base pt-4': scheme === SCHEMES.secondary,
              'text-gray-500 text-sm text-center': scheme === SCHEMES.primary,
            }"
          >
            {{ text }}
          </div>
          <div>
            <slot></slot>
          </div>
          <div
            v-if="isShowControlButtons"
            class="controls w-full flex flex-wrap flex-col-reverse md:flex-nowrap md:flex-row gap-1"
            :class="actionWrapperClass ? actionWrapperClass : 'justify-center'"
          >
            <Button
              class="cancel-btn min-w-[130px] h-10"
              :class="actionButtonClass"
              type="text"
              compact
              @onClick="cancel"
            >
              <div class="text-sm text-gray-600 font-semibold">Cancel</div>
            </Button>
            <Button
              class="save-btn min-w-[130px] h-10"
              :class="actionButtonClass"
              type="primary"
              compact
              :primaryFullSize="false"
              @onClick="apply"
            >
              <div class="text-sm text-white font-semibold">{{ applyBtnText }}</div>
            </Button>
          </div>
          <div
            v-if="showInfoButton"
            class="controls w-full flex justify-center"
          >
            <Button
              class="save-btn w-[200px] h-10"
              :class="actionButtonClass"
              type="primary"
              compact
              :primaryFullSize="false"
              @onClick="apply"
            >
              <div class="text-sm text-white font-semibold">{{ infoBtnText }}</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import DeleteIcon from '@/assets/icon_bucket.svg';
import CommentIcon from '@/assets/icon_comment.svg';
import CTAIcon from '@/assets/icon_CTA_large.svg';
import CloseIcon from '@/assets/icon_close.svg';

const SCHEMES = {
  primary: 'primary',
  secondary: 'secondary',
};

export default {
  name: 'DialogDefault',
  components: {
    CloseIcon,
    Button,
    DeleteIcon,
    CommentIcon,
    CTAIcon,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    containerClass: {
      type: String,
      default: '',
    },
    actionWrapperClass: {
      type: String,
      default: '',
    },
    actionBtnClass: {
      type: String,
      default: '',
    },
    isDarkOverlay: {
      type: Boolean,
      default: false,
    },
    isShowCloseBtn: {
      type: Boolean,
      default: false,
    },
    isShowControlButtons: {
      type: Boolean,
      default: true,
    },
    showInfoButton: {
      type: Boolean,
      default: false,
    },
    infoBtnText: {
      type: String,
      default: 'OK',
    },
    isTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    applyBtnText: {
      type: String,
      default: 'Ok',
    },
  },
  data() {
    return {
      SCHEMES,
    };
  },

  computed: {
    actionButtonClass() {
      if (this.actionBtnClass) {
        return this.actionBtnClass;
      }
      return this.scheme === SCHEMES.primary ? 'w-[130px]' : 'w-full';
    },
  },
  created() {
    document.querySelector('body').style.overflow = 'hidden';
  },

  destroyed() {
    document.querySelector('body').style.overflow = 'auto';
  },
  methods: {
    apply() {
      this.$emit('apply');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  @apply fixed flex justify-center items-center bottom-0 right-0;
  z-index: 100;
}

.dialog .dialog-container-default {
  @apply rounded-lg bg-white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.icon path {
  @apply stroke-orange;
}
</style>
